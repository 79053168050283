import { AxiosResponse } from "axios";
import { ApiEmailEntryPoints } from ".";
import { HttpMethods } from "../httpMethods";
import { CommonApiRoutes, Controllers, MessagingEntryPointApiRoutes, GeneralRoutes } from "src/utils/constants";
import { TextEntryPoint, TypeEnum } from "src/types/textEntryPoints";
import { BaseService } from "../baseService";

export class EmailEntryPointService extends BaseService implements ApiEmailEntryPoints {
    async getAssignToData(type: TypeEnum): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.EmailEntryPoint, CommonApiRoutes.AssignedToData, `?type=${type}`);
    }

    async getEmailEntryPointById(entryPointId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.EmailEntryPoint, '', `${entryPointId}`);
    }

    async createEmailEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.EmailEntryPoint, GeneralRoutes.Create, null, entryPoint);
    }

    async updateEmailEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.EmailEntryPoint, GeneralRoutes.Update, null, entryPoint);
    }

    async getAllEmailEntryPoints(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.EmailEntryPoint, MessagingEntryPointApiRoutes.AllEntryPoints);
    }
}