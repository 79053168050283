import { AxiosResponse } from "axios";
import { TextEntryPoint } from "src/types/textEntryPoints";
import { ApiWhatsAppEntryPoints } from ".";
import { HttpMethods } from "../httpMethods";
import { Controllers, MessagingEntryPointApiRoutes, GeneralRoutes } from "src/utils/constants";
import { BaseService } from "../baseService";

export class WhatsAppEntryPointService extends BaseService implements ApiWhatsAppEntryPoints {
    async createWhatsAppEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.WhatsAppEntryPoint, GeneralRoutes.Create, null, entryPoint);
    }

    async getAllWhatsAppEntryPoints(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.WhatsAppEntryPoint, MessagingEntryPointApiRoutes.AllEntryPoints);
    }

    async getWhatsAppEntryPointById(entryPointId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.WhatsAppEntryPoint, '', `${entryPointId}`);
    }

    async updateWhatsAppEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.WhatsAppEntryPoint, GeneralRoutes.Update, null, entryPoint);
    }
}