export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const CCMAXLENGTHS = {
  QueueName: 60,
  FlowNameMaxCharacters: 60,
  MediaFileName: 100,
  MediaFileText: 1000
}

export const SNACKBAR = {
  SnackBarAutoHideDuration: 6000,
}

export const HTTPSTATUSCODES = {
  StatusCodeSuccess: 200,
  StatusCodeForbidden: 403,
  StatusCodeNotFound: 404,
  StatusCodeConflict: 409,
  StatusCodeUnprocessableEntity: 422
}

export const ROWSPERPAGE = {
  DefaultValue: 10
}

export enum EventType {
  ClickAway = 'clickaway'
}

export const GeneratedMediaFileLanguage = {
  Default: 'en-US'
}

export const Controllers = {
  User: 'user',
  Queue: 'queue',
  Flow: 'flow',
  QueueUser: 'queue-user',
  QueueAnnouncement: 'queue-announcement',
  QueueEvent: 'queue-event',
  ApplicationInstance: 'application-instance',
  Schedule: 'schedule',
  Event: 'event',
  Announcement: 'announcement',
  AnnouncementMedia: 'announcement-media',
  Elsa: 'elsa',
  MessagingElsa: 'messaging/elsa',
  RefreshToken: 'refresh-token',
  GeneralSettings: 'general-settings',
  Reason: 'reason',
  Tenant: 'tenant',
  TenantManager: 'tenantmanager',
  WrapUpCollection: 'wrapup',
  Key: 'keys',
  GenericEntryPoint: 'generics/entry-points',
  MessagingQueue: 'messaging/queue',
  MessagingFlow: 'messaging/flow',
  MessagingQueueUser: 'messaging/queue/user',
  MessagingSchedule: 'messaging/schedule',
  Contact: 'contact',
  Skill: 'skill',
  EmailEntryPoint: 'messaging/email-entry-points',
  WhatsAppEntryPoint: 'messaging/whatsapp-entry-points',
  Deployments: 'deployments',
  Templates: 'templates',
  MessagingQueueTemplate: 'messaging/queue/template',
  MessagingEntryPoint: 'messaging/entry-points',
  QueueTemplate: 'voice/queue/template'
}

export const GeneralRoutes = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  List: 'list',
}

export const TemplateApiRoutes = {
  Messaging: "messaging",
  Voice: "voice"
}

export const AuthApiRoutes = {
  Timezone: 'timezone',
  VerifyUser: 'verify-user',
  Tenants: 'tenants',
  RefreshToken: 'web-admin',
  RevokeRefreshToken: 'revoke/web-admin',
  SignOut: 'sign-out-admin',
  Deployments: 'admin-deployments',
}

export const UserApiRoutes = {
  Users: 'users',
  ActiveUsers: 'active-users'
}

export const QueueApiRoutes = {
  Queues: 'queues',
}

export const GenericQueueApiRoutes = {
  Queues: 'queues',
}

export const QueueUserApiRoutes = {
  Membership: 'membership',
  VerifyLicenses: 'verify-license'
}

export const QueueAnnouncementApiRoutes = {
  Announcements: 'announcements',
  GetCallbackQueueAnnouncement: 'callback-announcement'
}

export const QueueEventApiRoutes = {
  Events: 'events',
  CreateEvents: "create-events"
}

export const FlowApiRoutes = {
  Flows: 'flows'
}

export const BotApplicationInstanceApiRoutes = {
  ApplicationInstances: 'app-instances',
  AllApplicationInstances: 'all-app-instances'
}

export const TenantApiRoutes = {
  GetAllTenants: 'tenants'
}

export const ScheduleApiRoutes = {
  Schedules: 'schedules',
  GeAllSchedules: 'schedules/all'
}

export const EventApiRoutes = {
  Events: 'events',
  AllEvents: "all-events"
}

export const AnnouncementApiRoutes = {
  Announcements: 'announcements',
  GetMaxNumberOfAnnouncements: 'max-number-of-announcements',
  GetAll: 'get-all',
  CreateDynamic: 'create-dynamic',
  DynamicAnnouncements: 'dynamic-announcements',
  GetDynamic: 'get-dynamic',
  DeleteDynamic: 'delete-dynamic',
  GetCollectionType: 'get-collection-type'
}

export const AnnouncementMediaApiRoutes = {
  GetMaxMediaFileSize: 'max-media-file-size',
  UploadToStorage: 'upload-to-storage',
  DeleteFromStorage: 'delete-from-storage',
  GenerateAudioFile: 'generate-audio-file',
  LanguagesAndVoices: 'languages-and-voices'
}

export const ContactApiRoutes = {
  ValidateImportFile: 'validate-import-file',
  Import: 'import',
  DeleteUpload: 'delete-upload'
}

export const DateTimeFormat = {
  DefaultFormat: "YYYY-MM-DD HH:mm",
  TimeFormat: "HH:mm"
}

export const MediaFileExtension = {
  Wav: 'wav',
  Xls: 'xls',
  XlsX: 'xlsx'
}

export const blobStorage = {
  AnnouncementsTemporaryFolderName: 'temp'
}

export const ElsaApiRoutes = {
  FlowPublished: 'flow-published',
  HasCycles: 'has-cycles',
  CleanUpVersions: 'clean-up-versions',
  GetSubflowParents: 'get-subflow-parents',
  EnabledOnEscape: 'enabled-on-escape',
  HasPreferredAgentRoutingEnabled: 'has-preferred-agent-routing-enabled',
  ElsaText: 'elsa-text'
}

export const GeneralSettingsRoutes = {
  Webhooks: 'webhooks',
  UpdateWebhooks: 'update-webhooks',
  Reasons: 'reasons',
  UpdateReasons: 'reasons',
  Resync: 'resync',
  ResyncAll: 'resyncall',
  Keys: 'keys',
  Permissions: 'permissions',
  SkillsEnabled: 'skills-enabled',
  RefreshContract: 'refresh-contract'
}

export const DefaultSettings = {
  Language: 'en',
}

export const WrapUpCollectionApiRoutes = {
  WrapUpCollection: 'wrapups',
  MaxLevelValue: 'maxlevelvalue',
  GetAllForQueue: 'get-all-for-queue'
}

export const GenericEntryPointApiRoutes = {
  GetAppInstances: 'app-instances',
  AllEntryPoints: 'all-entry-points'
}

export const Roles = {
  WebAdmin: 'WebAdmin',
  Supervisor: 'Supervisor',
  AgentPlus: 'AgentPlus',
  CC4AllAdmin: 'CC4AllAdmin'
}

export const Claims = {
  Role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
}

export const QueueDefaults = {
  ZeroTimer: '00:00:00',
  VoiceTimeout: '00:03:00',
  MessagingTimeout: '23:59:59',
  Priority: 1,
  VoiceItemUtilization: 75,
  MessagingItemUtilization: 25,
  RemoveFromUnavailableAfter: "00:03:00",
  NeverRemoveFromUnavailable: true,
  RoutingTimePeriod: "23:59:59",
  BullseyeRoutingCycle: "00:00:10",
  BullseyeRoutingCycleMinValue: 1,
  BullseyeRoutingCycleMaxValue: 300
}

export const QueueFieldLimits = {
  PriorityMinValue: 1,
  PriorityMaxValue: 100,
  ItemUtilizationMinValue: 1,
  ItemUtilizationMaxValue: 100
}

export const UsersTableHeaderKeys = {
  MemberAllUsers: "member-all-users-header",
  AgentName: "agent-name-header",
  AgentJoined: "agent-joined-header",
  AgentJoinLeave: "agent-joinLeave-header",
  AgentSupervisor: "agent-supervisor-header",
  AgentOperator: "agent-operator-header",
  AgentDesire: "agent-desire-header",
  AgentSkills: "agent-skills-header",
  AgentEdit: "agent-edit-header"
}

export const QueueTranslationKeys = {
  InvalidValue: 'Queue.InvalidValue',
  Required: 'Queue.Required'
}

export const SharedTranslationKeys = {
  NumbersAllowed: 'Shared.NumbersAllowed'
}

export const SkillsApiRoutes = {
  Skills: 'skills',
  AllSkills: 'all-skills'
}

export const SkillsFieldLimits = {
  SkillLevelMinValue: 0,
  SkillLevelMaxValue: 100
}

export const SkillsTranslationKeys = {
  Required: 'Skills.Required'
}

export const SkillsDefaults = {
  SkillLevel: 50
}

export const DashboardTranslationKeys = {
  Queues: "DashBoardSidebar.Queues"
}

export const CommonApiRoutes = {
  AssignedToData: 'assigned-to-data'
}

export const MessagingEntryPointApiRoutes = {
  GetEntryPoints: 'entry-points',
  AllEntryPoints: 'all-entry-points'
}

export const MessagingEntryPointTranslationKeys = {
  Required: 'TextEntryPoint.Required'
}

export const ToneStepUserInputOptions = {
  DTMFTone: "1",
  VoiceRecognition: "2",
  DTMFToneAndVoiceRecognition: "3"
}

export const TemplateRoutes = {
  GetVariables: 'variables',
  TestPrompt: "test-prompt"
}