import { AxiosResponse } from "axios";
import { ApiSkills } from ".";
import { HttpMethods } from "../httpMethods";
import { Controllers, GeneralRoutes, SkillsApiRoutes } from "src/utils/constants";
import { Skill } from "src/types/skill";
import { BaseService } from "../baseService";

export class SkillService extends BaseService implements ApiSkills {
    async getSkills(urlParams: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Skill, SkillsApiRoutes.Skills, urlParams);
    }

    async deleteSkills(data: string[]): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Skill, GeneralRoutes.Delete, null, data);
    }

    async getSkillById(skillId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Skill, '', `${skillId}`);
    }

    async createSkill(skill: Skill): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Skill, GeneralRoutes.Create, null, skill);
    }

    async updateSkill(skill: Skill): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Skill, GeneralRoutes.Update, null, skill);
    }

    async getAllSkills(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Skill, SkillsApiRoutes.AllSkills);
    }
}