import { AxiosResponse } from "axios";
import { HttpMethods } from "./httpMethods";
import { InvokeServiceArgs } from "./interfaces";
import { serviceCall } from "./service-call";

export class BaseService {
    async invokeServiceCall(httpMethod: HttpMethods, controller: string, route: string, urlParams: string = null, data: any = null): Promise<AxiosResponse> {
        const args: InvokeServiceArgs = {
            httpMethod: httpMethod,
            controller: controller,
            route: route,
            urlParams: urlParams,
            data: data
        };

        return serviceCall.invokeService(args);
    }
}