export default class UtilsHelper {
  public static readonly NameRegExp =
    "^[a-zA-ZÀ-ÿĳĲ0-9_]+( [a-zA-ZÀ-ÿĳĲ0-9_]+)*$";
  public static readonly GuidRegExp =
    /^($|[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})$/g;
  public static readonly PhoneNumberRegExp = /^($|\+?(\d+))$/g;
  public static readonly OnlyDigits = /^[0-9]*$/;
  public static readonly CallbackToneKeyRegEx = /^[0-9*]*$/;
  public static readonly EmailRegExp: RegExp =
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  public static getInitials = (name = ""): string => {
    return name
      .replace(/\s+/, " ")
      .split(" ")
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase())
      .join("");
  };

  public static areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((value, index) => value === arr2[index]);
  };

  public static mapToTokenArray = (stringArray: string[]) => {
    return stringArray.map((item) => ({
      value: `{${item}}`,
    }));
  };
}
