import React, { FC } from "react";
import { useStyles } from "src/theme/styles";
import { useTranslation } from "react-i18next";
import type { LicenseLimitData, LicenseCalculatedData } from "../../types/license";
import { LicenseInsightItem } from "src/components/license";
import {
    Typography,
  } from "@mui/material";

interface LicenseInsightViewProps {
	licenseLimitData: LicenseLimitData;
    licenseCalculatedData?: LicenseCalculatedData;
}
const LicenseInsightView: FC<LicenseInsightViewProps> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getRenewalDate = () => {
        const date = new Date(props.licenseLimitData.contractDateUntil);
        const day = date.getDate();
	    const month = date.getMonth() + 1;
        const year = date.getFullYear();

	    return `${day}/${month}/${year}`;
    }

    return (
        <React.Fragment>
            <Typography color="textPrimary" variant="body2" className={classes.insightText}>{`${t('License.ContractNumber')} ${props.licenseLimitData.contractNumber}`}</Typography>
            <Typography color="textPrimary" variant="body2" style={{display: "none"}}>{`${t('License.ContractRenewalDate')} ${getRenewalDate()}`}</Typography>
            <div className={classes.insightListContainer}>
                <LicenseInsightItem limitValue={props.licenseLimitData.agentsProVoice} calculatedValue={props.licenseCalculatedData.agentsProVoice} label={t('License.ProAgents')}/>
                <LicenseInsightItem limitValue={props.licenseLimitData.agentsProOmni} calculatedValue={props.licenseCalculatedData.agentsProOmni} label={t('License.ProOmni')}/>
                <LicenseInsightItem limitValue={props.licenseLimitData.agentsProPremium} calculatedValue={props.licenseCalculatedData.agentsProPremium} label={t('License.ProOmniPremium')}/>
                <LicenseInsightItem limitValue={props.licenseLimitData.supervisors} calculatedValue={props.licenseCalculatedData.supervisors} label={t('License.Supervisors')}/>
                <LicenseInsightItem limitValue={props.licenseLimitData.supervisorsPremium} calculatedValue={props.licenseCalculatedData.supervisorsPremium} label={t('License.SupervisorsPremium')}/>
                <LicenseInsightItem limitValue={props.licenseLimitData.operators} calculatedValue={props.licenseCalculatedData.operators} label={t('License.Operators')}/>
                <LicenseInsightItem limitValue={props.licenseLimitData.operatorsPremium} calculatedValue={props.licenseCalculatedData.operatorsPremium} label={t('License.OperatorsPremium')}/>
                <LicenseInsightItem isSingleValue singleValue={props.licenseCalculatedData.admins} label={t('License.Admins')}/>
                <LicenseInsightItem isSingleValue singleValue={props.licenseCalculatedData.totalUsers} label={t('License.Total')}/>
                <LicenseInsightItem hasInsightsLicense={props.licenseLimitData.hasInsightsLicense} label={t('License.Insights')}/>
            </div>
        </React.Fragment>
    );
}

export default LicenseInsightView;