import { ApiUsers } from ".";
import { AxiosResponse } from 'axios';
import { HttpMethods } from "../httpMethods";
import { UserDto, UserRightsChanged } from "../../types/user";
import { Controllers, GeneralRoutes, UserApiRoutes } from "../../utils/constants";
import { BaseService } from "../baseService";

export class UserService extends BaseService implements ApiUsers {
    async getLicenseDataAndUsers(urlParams: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.User, UserApiRoutes.Users, urlParams);
    }

    async updateUsers(data: UserRightsChanged[]): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.User, UserApiRoutes.Users, null, data);
    }

    async getActiveUsers(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.User, UserApiRoutes.ActiveUsers);
    }

    async getUserById(userId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.User, '', `${userId}`);
    }

    async updateUser(user: UserDto): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.User, GeneralRoutes.Update, null, user);
    }
}